'use client';

import { cn } from '@/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useState } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sentences: ReactNode[];
}

export default function TextSlideshow({
  sentences,
  className,
  ...rest
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sentences.length - 1 ? 0 : prevIndex + 1
    );
  }, [sentences.length]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isPaused) {
      timer = setInterval(nextSlide, 3000); // Change sentence every 3 seconds
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isPaused, nextSlide]);

  return (
    <div
      className={cn(className)}
      {...rest}
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <AnimatePresence mode='wait'>
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          className={cn(
            `rounded-lg p-4 text-center text-2xl font-semibold text-gray-800 transition-colors duration-300`
          )}
        >
          {sentences[currentIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
