'use client';

import { motion } from 'framer-motion';

type Props = {};

export default function ImageParallax({}: Props) {
  return (
    <motion.img
      src='/home-screen.png'
      alt='App home screen'
      style={{
        boxShadow: '0 0 40px 0 rgba(0, 0, 0, 0.25)',
        objectFit: 'cover',
        objectPosition: 'left top',
      }}
      className='z-5 size-full rounded-lg'
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    ></motion.img>
  );
}
